export const Permission = {
  ManageAdmin: "manage_admin",
  JobCertification: "job_certification",
  ManageCreator: "manage_creator",
  ManageCompany: "manage_company",
  ImpersonateCreator: "impersonate_creator",
  ImpersonateCompany: "impersonate_company",
  JobContentApproval: "job_content_approval",
  JobCancellation: "job_cancellation",
  CampaignApproval: "campaign_approval",
  JobMediation: "job_mediation",
  JobData: "job_data",
  WireTransfer: "wire_transfer",
  ManageVideo: "manage_video",
  ManageTikTokAd: "manage_tiktok_ad",
  RecurringCampaign: "recurring_campaign",
  ManagePromoCoupons: "manage_promo_coupons",
};
